import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './index.scss';
import registerServiceWorker from './registerServiceWorker';
import Home from './containers/Home';
import OurProduct from './containers/OurProduct';
import ContactUs from './containers/ContactUs';
import NotFound from './containers/NotFound';
import PrivacyPolicy from './containers/PrivacyPolicy';
import CookiePolicy from './containers/CookiePolicy';

const root = createRoot(document.getElementById('root'));
root.render(<BrowserRouter>
    <Routes>
        <Route index element={<Home />} />
        <Route exact path='/our-product' element={<OurProduct />} />
        <Route exact path='/contact-us' element={<ContactUs />} />
        <Route exact path='/contact-us/:action' element={<ContactUs />} />
        <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route exact path='/cookie-policy' element={<CookiePolicy />} />
        <Route path='*' element={<NotFound />} />
    </Routes>
</BrowserRouter>);

registerServiceWorker();
