import React, { useEffect, useState } from 'react';
import Header from '../components/Header/Header';
import Hero from '../components/Hero/Hero';
import Footer from '../components/Footer/Footer';
import './PrivacyPolicy.scss';
import axios from 'axios';

const PrivacyPolicy = () => {
    const [content, setContent] = useState({ isWorking: true, data: '' });

    useEffect(() => {
        (async () => {
            const response = await axios.get('https://www.iubenda.com/api/privacy-policy/10691543/no-markup');
            setContent({ isWorking: false, data: response.data.content })
            window.scrollTo(0, 0);
        })();
    }, []);

    return (
        <div className="privacy-policy-page">
            <Header />
            <Hero
                title='Privacy Policy'
                className={['secondary']}
            />
            <div className='container'>
                <div className='row justify-content-center'>
                    {content.isWorking
                        ? <div className='col-12 col-md-8'>Loading</div>
                        : <div className='col-12 col-md-8' dangerouslySetInnerHTML={{ __html: content.data }}></div>
                    }
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default PrivacyPolicy;